import { BaseModuleProps, PageContent } from '../../types';
import React from 'react';
import { useSiteData } from '../../providers/SiteDataProvider';
import ResponsiveImage from '../structure/ResponsiveImage';
import ImageUtil from '../../util/ImageUtil';

const TextImage: React.FC<
    Omit<BaseModuleProps, 'pageContent'> & {
        pageContent: Pick<PageContent['content'], 'text' | 'image'>;
    }
> = ({ pageContent, moduleHead }) => {
    const { textUtil } = useSiteData();
    const textContent = textUtil.formatText(pageContent.text);

    return (
        <div
            className="m-text-image m-text-image--image-right clearfix"
            data-t-name="TextImage"
        >
            {moduleHead}
            <div
                className="m-text-image__image "
                data-t-lightbox="0e120491a5708401181c9b7287347f72eb983e88ab562e37dfa8a9b7d23407d6"
            >
                <ResponsiveImage img={ImageUtil.getImageCompat(pageContent)!} />
                <div dangerouslySetInnerHTML={{ __html: textContent }} />
            </div>
        </div>
    );
};

export default TextImage;
